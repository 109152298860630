import React, { useState, useEffect } from 'react';    
import { useTheme, useMediaQuery, Typography, Container, Fab, Backdrop, Box, Modal, createTheme, ThemeProvider, CssBaseline, Grid, TextField, IconButton, Card, CardContent, CardMedia } from '@mui/material';    
import { Button, Menu, MenuItem } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';    
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';    
import CheckIcon from '@mui/icons-material/Check';    
import CloseIcon from '@mui/icons-material/Close';    
import './index.css';    
    
    
const modalStyle = {    
  position: 'absolute',    
  top: '50%',    
  left: '50%',    
  transform: 'translate(-50%, -50%)',    
  width: '70%', // Større bredde    
  height: '40%', // Større højde    
  minHeight: '175px', // Mindste højde
  bgcolor: 'background.paper',    
  boxShadow: 24,    
  p: 4,    
  border: '2px solid lightgray', // Lysegrå border    
  borderRadius: '10px', // Afrundede hjørner    
};    
    
const theme = createTheme({    
  // Dit tema setup    
});     

function App() {    
    
  const [dataFilename, setDataFilename] = useState(() => {
    return localStorage.getItem('dataFilename') || 'birds1.json';
  });

  const [Data, setData] = useState([]); // Opdateret til at bruge state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (deckName) => {
    switch (deckName) {
      case 'Arcanum':
        setDataFilename('arcanum.json');
        break;
      case 'Birds':
        setDataFilename('birds1.json');
        break;
      case 'Mirage': // Tilføjer Mirage case
        setDataFilename('mirage.json');
        break;
      case 'Ukiyo-e': // Tilføjer Ukiyo-e case
        setDataFilename('ukiyo-e.json');
        break;
      default:
        setDataFilename('birds1.json');
    }
    handleClose();
  };

    // Dynamisk indlæsning af data baseret på dataFilename
    useEffect(() => {
      localStorage.setItem('dataFilename', dataFilename);
      console.log(`Loading data from ${dataFilename}`);
      const loadData = async () => {
        const response = await fetch(`./data/${dataFilename}`);
        const jsonData = await response.json();
        setData(jsonData);
        console.log(`Data loaded for ${dataFilename}`);
      };
      loadData();
    }, [dataFilename]);

    
    const imageBasePath = dataFilename === 'birds1.json' 
    ? "/images/tarotset/birds1/480/" 
    : dataFilename === 'arcanum.json'
    ? "/images/tarotset/arcanum/480/"
    : dataFilename === 'mirage.json'
    ? "/images/tarotset/mirage/480/"
    : "/images/tarotset/ukiyo/480/";
    
    console.log(`Rendering with dataFilename: ${dataFilename} and basePath: ${imageBasePath}`);
    

  // States for modal    
  const [openModal, setOpenModal] = useState(false);    
  const [question, setQuestion] = useState('');    
  const [savedQuestion, setSavedQuestion] = useState('');    
    
  const handleOpenModal = () => setOpenModal(true);    
  const handleCloseModal = () => setOpenModal(false);    
  const handleSaveQuestion = () => {    
    setSavedQuestion(question);    
    handleCloseModal();    
  };    
    
  
  const [selectedCardIndices, setSelectedCardIndices] = useState(() => {
    const savedIndices = localStorage.getItem('selectedCardIndices');
    return savedIndices ? JSON.parse(savedIndices) : [78, 78, 78];
  }); 
const [flippingCard, setFlippingCard] = useState(null);    
const cardTitles = ["Self", "Situation", "Challenge"];   
const cardTitlesSmall = ["Self", "Situation", "Challenge"];  
//const cardTitlesSmall = ["Self", "Situ.", "Chal."];    
    
const muiTheme = useTheme();    
const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'));    
  
 
  
const handleCardClick = (cardIndex) => {    
  console.log('Før ændring:', selectedCardIndices);    
  console.log('Klikket på kort med index:', cardIndex);    
  
  
  if (selectedCardIndices[cardIndex] === 78) { // Sikrer at kun ikke-valgte kort kan interageres med    
    console.log('Kort', cardIndex, 'er valgt til at flippe.');    
    setFlippingCard(cardIndex);    
    setTimeout(() => {        
      const newIndices = [...selectedCardIndices];        
      newIndices[cardIndex] = getRandomCardIndex(selectedCardIndices);        
      setSelectedCardIndices(newIndices);        
      // Vent 250ms før du sætter flippingCard tilbage til null        
      setTimeout(() => setFlippingCard(null), 250);        
    }, 250);   }    
};    
  
  
// Ensure that the generated random index is within the range of the Data array    
const getRandomCardIndex = (excludeIndices) => {    
  console.log('Ekskluderede indekser:', excludeIndices);    
  let randomIndex;    
  do {    
    randomIndex = Math.floor(Math.random() * Data.length);    
  } while (excludeIndices.includes(randomIndex));    
  console.log('Genereret tilfældig indeks:', randomIndex);    
  return randomIndex;    
};    
  
  
 // The main render return of the component    
return (    
  <ThemeProvider theme={theme}>    


      {/* Tilføjelse af en knap, der åbner menuen */}
      <Button
  aria-controls="deck-menu"
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
  onClick={handleClick}
  sx={{
    bgcolor: 'primary.main',
    color: 'white',
    padding: '6px 12px', // Mindre padding
    fontSize: '0.875rem', // Mindre fontstørrelse
    ':hover': {
      bgcolor: 'primary.dark',
    },
    margin: '10px', // Mindre margin
  }}
>
  Vælg Deck
</Button>

<Menu
  id="deck-menu"
  anchorEl={anchorEl}
  open={open}
  onClose={handleClose}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
  sx={{
    marginTop: '40px',
    '.MuiPaper-root': { // Styler Paper-komponenten, som omslutter Menu-komponenten
      bgcolor: 'background.paper',
      color: 'text.primary', // Sætter tekstfarven til primær tekstfarve fra temaet
      boxShadow: 24,
      backgroundColor: 'black', // Sætter baggrundsfarven på menuen til sort
    },
    '.MuiMenuItem-root': {
      fontSize: '0.875rem',
      minHeight: 'auto',
      padding: '4px 20px',
    },
  }}
>
  <MenuItem onClick={() => handleMenuItemClick('Arcanum')} sx={{ color: 'white' }}>Arcanum</MenuItem>
  <MenuItem onClick={() => handleMenuItemClick('Birds')} sx={{ color: 'white' }}>Birds</MenuItem>
  <MenuItem onClick={() => handleMenuItemClick('Mirage')} sx={{ color: 'white' }}>Mirage</MenuItem> 
  <MenuItem onClick={() => handleMenuItemClick('Ukiyo-e')} sx={{ color: 'white' }}>Ukiyo-e</MenuItem> 
</Menu>
    <CssBaseline />    
    <div style={{     
        backgroundImage: `url('/images/tarotset/arcanum/tarotbackground29.webp')`,    
        backgroundSize: 'cover',    
        backgroundColor: 'lightblue',    
        backgroundRepeat: 'no-repeat',    
        minHeight: '100vh',    
        Height: '100vh',    
        Widht: '100vh',    
        paddingBottom: '64px', // Padding at bottom equal to AppBar height,    
 
    }}>    
      <Container className="containerWithPerspective" sx={{}}>    
        <Grid container spacing={isSmallScreen ? 0 : 2} justifyContent="center">    
          {selectedCardIndices.map((cardIndex, index) => {    
            const cardData = Data[cardIndex]; // Get the card data based on the current index    
            // If cardData is not available (index is null initially), show a placeholder or just return null    
            if (!cardData) {    
              return <Grid item xs={isSmallScreen ? 4 : 3} key={index}>Card Placeholder</Grid>;    
            }    
            return (    
              <Grid item xs={isSmallScreen ? 4 : 3} key={index}>    
                <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none',                                 }}    
                          
                      onClick={() => handleCardClick(index)}>    
                  <CardContent>    
                    <Typography variant="h5" sx={{     
                                fontFamily: 'Times New Roman, serif',     
                                textAlign: 'center',    
                                fontSize: {    
                                  xs: '0.50rem', // Smaller screen sizes    
                                  sm: '0.75rem',    
                                  md: '1rem',    
                                  lg: '1.5rem', // Larger screen sizes    
                                  xl: '2rem' // Very large screen sizes    
                                },    
                                }}>    
                      <span style={{ fontSize: '2em', color: 'White' }}>{(isSmallScreen ? cardTitlesSmall[index] : cardTitles[index]).charAt(0)}</span><span style={{ color: 'white' }}>{(isSmallScreen ? cardTitlesSmall[index] : cardTitles[index]).slice(1)}</span>    
                    </Typography>    
                    <CardMedia    
                      component="img"    
                      image={`${imageBasePath}tarotcard${cardData.id}.webp`}    
                      alt={cardData.title}     
                      sx={{ Height: '100%', width: '100%', objectFit: 'contain' }}    
                      className={flippingCard === index ? 'flip-animation cardWithPreserve3d' : 'cardWithPreserve3d'}
                    />    
                    {    
<>                        <Typography variant="body2" sx={{ fontFamily: 'Times New Roman, serif', textAlign: 'center', color: 'White', fontSize: {    
                                  xs: '0.50rem', // Smaller screen sizes    
                                  sm: '0.75rem',    
                                  md: '1rem',    
                                  lg: '1.5rem', // Larger screen sizes    
                                  xl: '2rem' // Very large screen sizes    
                                } }}>{cardData.tarotTitle}</Typography>    
                      <Typography variant="body2" sx={{ fontFamily: 'Times New Roman, serif', textAlign: 'center', color: 'White', fontSize: {    
                                  xs: '0.50rem', // Smaller screen sizes    
                                  sm: '0.75rem',    
                                  md: '1rem',    
                                  lg: '1.5rem', // Larger screen sizes    
                                  xl: '2rem' // Very large screen sizes    
                                } }}>{cardData.name}</Typography>    
                      </>    
                    }    
                  </CardContent>    
                </Card>    
              </Grid>    
            );    
          })}    
        </Grid>    
      </Container>    
    </div>    
  
    {/* Floating action buttons for user interaction */}    
    <Fab color="secondary" aria-label="new question" sx={{ position: 'fixed', bottom: '2em', right: '2em' }} onClick={handleOpenModal}>    
      <RefreshIcon />    
    </Fab>    
   
    <Fab color="secondary" aria-label="change spread type" sx={{ position: 'fixed', bottom: '2em', left: '2em' }} >    
      <QuestionAnswerIcon />    
    </Fab>    
  
    <Modal    
      open={openModal}    
      onClose={handleCloseModal}    
      closeAfterTransition    
      BackdropComponent={Backdrop}    
      BackdropProps={{    
        timeout: 500,    
      }}    
    >    
      <Box sx={modalStyle} style={{ backgroundColor:'black'}}>    
        <TextField    
          autoFocus    
          margin="dense"    
          id="question"    
          label="Spørg Oraklet"    
          type="text"    
          fullWidth    
          placeholder="Hvad skal mit næste skridt være i min karriere?"    
          value={question}    
          onChange={(e) => setQuestion(e.target.value)}    
          defaultValue={savedQuestion}    
          sx={{    
            input: { color: 'white' }, // Dette ændrer skriftfarven i input feltet til hvid    
            '& .MuiFilledInput-underline:before': { borderBottomColor: 'white' }, // Underline farve før input (default)    
            '& .MuiFilledInput-underline:after': { borderBottomColor: 'white' }, // Underline farve efter input (når fokuseret)    
          }}    
          InputLabelProps={{    
            style: { color: 'white' }, // Dette ændrer label farven til hvid    
          }}    
        />    
        <Box textAlign='center' marginTop={2}>    
          <IconButton onClick={handleCloseModal} color="primary" sx={{ color: 'white' }}>    
            <CloseIcon />    
          </IconButton>    
          <IconButton onClick={handleSaveQuestion} color="primary" sx={{ color: 'white' }} disabled={!question}>    
            <CheckIcon />    
          </IconButton>    
        </Box>    
      </Box>    
    </Modal>    

  </ThemeProvider>    
);    
}    
  
export default App;    
